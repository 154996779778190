<template>
  <div>
    <b-card v-if="!isLoading">
      <div class="d-flex align-items-center ">
        <b-avatar rounded="lg" variant="light-warning">
          <i class="card-icon ti ti-brand-hipchat " />
        </b-avatar>
        <h6 class="ml-1 mb-0">{{ forum.title }}</h6>
      </div>

      <div class="mt-1" v-html="forum.description" />

      <div class="mt-1 pt-1 border-top">
        <h5 class="mb-1">Komentar ({{ forum.forum_replies.length }})</h5>
        <div v-if="forum.forum_replies.length > 0">
          <div
            class="d-flex mb-1"
            v-for="comment in forum.forum_replies"
            :key="comment.id"
          >
            <b-avatar
              variant="light-primary"
              :src="comment.user.avatar"
              :text="avatarText(comment.user.name)"
            />
            <div class="ml-1">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="mb-0">
                    {{ comment.user.name }}
                  </h6>
                </div>
                <small class="text-muted">{{
                  toHumanDate(comment.created_at)
                }}</small>
                <span class="small-gap-top">
                  {{ comment.reply }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="forum.forum_replies.length === 0" class="text-center">
          <small>Belum Ada Komentar</small>
        </div>
      </div>
    </b-card>

    <b-card v-if="!isLoading">
      <h5 class="mb-1">Tambah Komentar</h5>

      <div class="d-flex">
        <b-avatar
          variant="light-success"
          :src="currentUser.avatar"
          :text="avatarText(currentUser.name)"
        />
        <div class="ml-1 w-100">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">
                Anda
              </h6>
            </div>
            <small class="text-muted">{{ toHumanDate(new Date()) }}</small>
            <b-form-textarea
              v-model="formData.reply"
              class="mt-1 "
              placeholder="Tulis Komentar"
              rows="3"
              no-resize
            />
          </div>
          <b-button
            class="float-right mt-1"
            variant="primary"
            @click="sendComment()"
          >
            <i v-if="!buttonIsLoading" class="ti ti-send" />
            <b-spinner v-if="buttonIsLoading" />

            Kirim
          </b-button>
        </div>
      </div>
    </b-card>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center mt-4">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Forum
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Components
import { BCard, BButton, BAvatar, BSpinner, BFormTextarea } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// *Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, taskType, toHumanDate } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BSpinner,
    BFormTextarea,
  },
  data() {
    return {
      isLoading: true,
      buttonIsLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      forumId: this.$router.currentRoute.params.forumId,
      currentUser: {},

      // data
      forum: {},
      formData: {
        reply: '',
        attachment: '',
      },
    }
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem('userData'))
    await client
      .get(
        `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/forum/${this.forumId}`
      )
      .then((response) => {
        this.forum = response.data.data

        this.isLoading = false
      })
  },
  methods: {
    avatarText,
    taskType,
    toHumanDate,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    async sendComment() {
      const data = new FormData()
      data.append('reply', this.formData.reply)
      await client
        .post(
          `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/forum/${this.forumId}/comments`,
          data
        )
        .then((response) => {
          this.formData.reply = ''
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Komentar Berhasil Dibuat'
          )
          this.refreshForumData()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async refreshForumData() {
      this.isLoading = true

      await client
        .get(
          `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/forum/${this.forumId}`
        )
        .then((response) => {
          this.forum = response.data.data

          this.isLoading = false
        })
    },
  },
}
</script>

<style>
p {
  margin-bottom: 0;
}
</style>
